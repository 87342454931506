import React from "react";
import "./style.scss";
import { Heading1, Paragraph } from "../../shared/layout";
import openai from "../../assets/img/openai.svg";
import tensorflow from "../../assets/img/tensorflow.svg";
import prophet from "../../assets/img/prophet.svg";
import huggingface from "../../assets/img/huggingface_logo.svg";
import Button from "../../components/Button";

const Landing = () => {
  return (
    <div className="landing-container">
      <div className="landing-content">
        <div>
          <div>
            <Heading1>
              Artificial Intelligence <br />
              <span style={{ color: "#039874" }}>Playground</span>
            </Heading1>
            <div style={{ marginTop: "4rem" }} className="landing-text">
              <Paragraph>
                AI Playground is where I deploy and test software I build, with
                open source and custom Machine Learning and Deep Learning
                models. The tools deployed here are built by Stephen Sanwo,
                mostly with open source models. Check out my blog at{" "}
                <a
                  href="https://blog.stephensanwo.dev"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://blog.stephensanwo.dev
                </a>{" "}
                and follow me on twitter @stephensanwo
              </Paragraph>
            </div>

            <div className="logo-container">
              <img alt="openai-logo" src={openai} width="80px" />
              <img alt="tensorflow-logo" src={tensorflow} width="80px" />
              <img alt="prophet-logo" src={prophet} width="80px" />{" "}
              <img alt="huggingface-logo" src={huggingface} width="30px" />
            </div>

            <div style={{ marginTop: "4rem" }}>
              <Button
                button_title="Explore"
                button_link="/apps"
                icon_type="next"
                button_size="lg"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing;
