import React from "react";
import "./style.scss";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../components/Breadcrumbs";
import { Heading4, Heading6 } from "../../shared/layout";
import { License32, Chat32 } from "@carbon/icons-react";
// import nlp from "../../assets/images/svg/nlp.svg";
// import summarize from "../../assets/images/svg/summarize.svg";

const Apps = () => {
  return (
    <div className="container">
      <div className="content">
        <Breadcrumbs data={[{ title: "Explore", link: "/navigation" }]} />
        <div className="header">
          <Heading4>Welcome, what do you want to do?</Heading4>
        </div>
        <div className="nav-categories">
          <Link style={{ textDecoration: "none" }} to="/gptj-chatbot">
            <div className="nav-category">
              <div>
                <Heading6>GPT-J Chat Bot</Heading6>
                <p>
                  Take the GPT-J 6b AI Model for a spin by engaging it in a
                  conversation
                </p>
                <small>Development</small>
              </div>

              <Chat32 style={{ width: "48px", height: "48px" }} />
            </div>
          </Link>
          <Link to="/apps" style={{ textDecoration: "none" }}>
            <div className="nav-category">
              <div>
                <Heading6>Essay Summarisation</Heading6>
                <p>
                  Too lazy to read?, Get a summarized version of a long article
                  or book.
                </p>
                <small>Development</small>
              </div>

              <License32 style={{ width: "48px", height: "48px" }} />
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Apps;
