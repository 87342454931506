import { ArrowLeft32, ArrowRight32 } from "@carbon/icons-react";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Heading4 } from "../shared/layout";

interface ButtonProps {
  button_title: string;
  button_link: string;
  button_size: "lg" | "md" | "sm" | "xs";
  icon?: boolean;
  icon_type?: "back" | "next";
  onClick?: React.ReactNode;
}

const buttonSize = {
  lg: {
    icon: "48px",
    text: "2rem",
  },

  md: {
    icon: "32px",
    text: "1.6rem",
  },
  sm: {
    icon: "24px",
    text: "1.4rem",
  },

  xs: {
    icon: "16px",
    text: "1rem",
  },
};

const ButtonContainer = styled.div`
  width: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  height: 60px;
  border-radius: 10px;
  background-color: #039874;
  &:hover {
    background-color: #66d19e;
  }
`;

const Button: React.FC<ButtonProps> = (props) => {
  const userSize = buttonSize[props.button_size];
  return (
    <ButtonContainer>
      <Link to={props.button_link} style={{ textDecoration: "none" }}>
        <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
          {props.icon_type === "back" ? (
            <ArrowLeft32
              style={{ width: userSize.icon, height: userSize.icon }}
              fill="#000"
            />
          ) : (
            ""
          )}
          <Heading4 style={{ fontSize: userSize.text }}>
            {props.button_title}
          </Heading4>
          {props.icon_type === "next" ? (
            <ArrowRight32
              style={{ width: userSize.icon, height: userSize.icon }}
              fill="#000"
            />
          ) : (
            ""
          )}
        </div>
      </Link>
    </ButtonContainer>
  );
};

export default Button;
