import styled from "styled-components";
import { size } from "./media";

export const Heading1 = styled.h1`
  font-size: 6rem;
  font-family: "Dev-Title", "sans-serif", "Helvetica Neue";
  font-weight: 400;
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: #000;
  line-height: 1;
  @media (min-width: 0px) and (max-width: ${size.tablet}) {
    font-size: 4rem;
  }
`;

export const Heading4 = styled.h4`
  font-size: 2rem;
  font-family: "Uber Move", "sans-serif", "Helvetica Neue";
  font-weight: 400;
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: #000;
  line-height: 1;
  @media (min-width: 0px) and (max-width: ${size.tablet}) {
    font-size: 1.5rem;
  }
`;

export const Heading6 = styled.h4`
  font-size: 1.2rem;
  font-family: "Uber Move", "sans-serif", "Helvetica Neue";
  font-weight: 400;
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: #000;
  line-height: 1;
  @media (min-width: 0px) and (max-width: ${size.tablet}) {
    font-size: 1rem;
  }
`;

export const Paragraph = styled.p`
  font-size: 1rem;
  font-weight: 400;
  font-family: "Dev-Title", "sans-serif", "Helvetica Neue";
  /* margin-top: 1rem;
  margin-bottom: 1rem; */
  color: #000;
  line-height: 1.5;
  @media (min-width: 0px) and (max-width: ${size.tablet}) {
    font-size: 0.8rem;
  }
`;
