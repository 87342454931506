import React, { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.scss";
import Navbar from "./components/Navbar";
// import GptjChatbot from "./GptjChatbot";
import Landing from "./pages/Landing";
import Apps from "./pages/Apps";
// import Nav from "./pages/Nav";

function App() {
  const [isNavOpen, setIsNavOpen] = useState("closed");
  return (
    <BrowserRouter>
      <Navbar isNavOpen={isNavOpen} setIsNavOpen={setIsNavOpen} />
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/apps" element={<Apps />} />

        {/* <Route exact path="/navigation">
          <Nav />
        </Route>
   
        <Route path="/gptj-chatbot">
          <GptjChatbot />
        </Route> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
